<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ข้อมูลลูกค้า</div>
      <div style="width: 400px;">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="searchData"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="ml-5" @click="add()"
        ><v-icon left small>mdi-plus</v-icon>เพิ่มลูกค้า</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2  row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      @click:row="edit"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.phone_number`]="{ item }">
        <span>{{ phoneNumberFormat(item.phone_number) }}</span>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    pageCount: 0,
    showPhoneNumber: false,
    headers: [
      { text: '#', value: '_id', filterable: false, sortable: false, width: 1},
      { text: 'ลูกค้า', value: 'name', align: 'left' },
      { text: 'เบอร์ติดต่อ', value: 'phone_number', align: 'left' },
      {
        text: 'ทะเบียนรถ',
        value: 'car_ownership',
        align: 'left',
        width: '700'
      },
      { text: '', value: 'actions', sortable: false, filterable: false }
    ],
    list: [],
    car_ownership: [],
    car_plate: [],
    filter: {
      branch_id: '',
      item_per_page: 10,
      page: 1,
      start_with: '',
      car_ownership: [],
      car_plate: []
    },
    permission: {},
  }),
  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
    this.getUserGroup();
  },
  methods: {
    add() {
      this.$router.push({ name: 'customer-customer-create' });
    },
    edit(item) {
      this.$router.push({
        name: 'customer-customer-update',
        params: { id: item._id }
      });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { customer_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              )
            };

            await this.$axios
              .post(`${this.$baseUrl}/customer/delete_customer`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/customer/get_customer`, body)
        .then(res => {
          this.list = res.result.data;
          console.log(this.list);

          for (let i = 0; i < this.list.length; i++) {
            this.list[i].car_ownership = this.list[i].car_ownership;
            for (let k = 0; k < this.list[i].car_ownership.length; k++) {
              this.list[i].car_ownership[k] = this.list[i].car_ownership[
                k
              ].car_plate;
              // this.list[i].car_ownership.push(this.car_ownership[i].car_plate);
            }
          }

          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async searchData() {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.getData()
      }, 1000);
    },
    async getUserGroup() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
            console.log("No getGroupPermissions")
        };

        this.permission.show_phone_number = this.$store.state.group_permissions.show_phone_number;

      } catch (error) {
        console.error('Error getUserGroup in print:', error);
        Sentry.captureException(error);
        this.permission.show_phone_number = true;
      };
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    phoneNumberFormat(val) {
      if (val) {
        if (!this.permission.show_phone_number) return 'XXX-XXXXXXX';
        return val.substring(0, 3) + '-' + val.substring(3, val.length);
      } else return val;
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
